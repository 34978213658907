import React from "react";
import Navbar from "./HeaderComponents/Navbar";
import "../../assets/styles/header/header.css";
import userIcon from "../../assets/images/identity/icons/user.png";
import logo2 from "../../assets/images/identity/icons/fbsLogo.png";
import { Link } from "react-router-dom";
import { handleSideBarToggle } from "../../features/uiSlice";
import { useDispatch } from "react-redux";
import { logOutUser } from "../../api/auth/authApi";
import { clearUser } from "../../features/userSlice";

function Header() {
	const dispatch = useDispatch();

	const handleOnLogoutClick = () => {
		logOutUser();
		dispatch(clearUser());
	};

	return (
		<header className="header">
			<div className="header__imageContainer">
				<img
					src={logo2}
					alt="toggle menu visibility"
					className="header__menuImage"
					onClick={() => dispatch(handleSideBarToggle())}
				/>
			</div>

			<Navbar />

			<div className="header__imageContainer">
				<Link to="/user" className="header__imageContainer">
					<img
						src={userIcon}
						alt="toggle menu visibility"
						className="header__menuImage"
					/>
				</Link>
				<p
					style={{
						padding: "0 1rem",
						textDecoration: "underline",
						cursor: "pointer",
					}}
					onClick={handleOnLogoutClick}
				>
					Logout
				</p>
			</div>
		</header>
	);
}

export default Header;
