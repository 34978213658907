import React from "react";
import "../../../assets/styles/modules/videoCard.css";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentMedia } from "../../../features/mediaSlice";
import { useNavigate } from "react-router-dom";

function VideoCardTwo({ m }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const userPlaylist = useSelector(
		(state) => state.userState.user.user.accessDetails.playlists
	);

	const handleMediaClick = () => {
		console.log(userPlaylist);
		const isPlaylistIdInArray = userPlaylist.some(
			(playlist) => playlist._id === m.playlist._id
		);
		if (!isPlaylistIdInArray) {
			alert(
				"You don't have access to the this playlist. Please subscribe or contact admin if it is a mistake."
			);
		} else {
			console.log("The playlist ID is not in the array");
			dispatch(setCurrentMedia(m));
			navigate("/video");
		}
		dispatch(setCurrentMedia(m));
		// navigate("/video");
	};
	return (
		<div className="videoCard__container--two" onClick={handleMediaClick}>
			<div className="videoCard__thumbnailContainer--two">
				<img
					src={m.links.thumbnailPath}
					alt="thumbnail"
					className="videoCard__thumbnail"
				/>
			</div>
			<h4 className="videoCard__title--two">{m.meta.title}</h4>
		</div>
	);
}

export default VideoCardTwo;
