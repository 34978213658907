import React from "react";

function PrivacyPolicy() {
	return (
		<div className="edu-privacy-policy-area edu-privacy-policy edu-section-gap bg-color-white">
			<div className="container">
				<div className="row g-5">
					<div className="content">
						<p>
							The website “https://firstbitsolutions.com/” ( “Website”) is owned
							and operated by firstbitsolutions (“Company”). [The Company is
							engaged in the business of disseminating educational content to
							users through different media including but not limited to audio,
							visual, textual, or physical means, whether live or pre-recorded,
							specific details of which are more fully described in the sales
							brochures made available to you under the web page for each
							specific course on our Website (“Services”).]
						</p>
						<p>
							This privacy policy (“Privacy Policy”) sets out the privacy
							practices of the Company with respect to the usage of the Website
							and any other platforms that may be made available to you by the
							Company, in the future including, but not limited to, mobile
							applications and electronic devices in connection with our
							Services.
						</p>
						<p>
							This document is published in accordance with the provisions of
							the Information Technology Act, 2000 and the rules made thereunder
							that require publishing the rules and regulations, privacy policy
							on an online portal of the Company. We request you to go through
							this Privacy Policy carefully before you decide to access the
							Website and avail the Services.
						</p>
						<p>
							For the purposes of this Privacy Policy, the words “us,” “we,” and
							“our” refer to the Company and all references to “you,” “your” or
							“user,” as applicable, means the person who accesses or uses the
							Website and avails the Services in any manner or capacity. The
							protection and security of your personal information is our top
							priority and we have taken all reasonable measures to protect the
							confidentiality of the user information and its transmission
							through the internet.
						</p>
						<p>
							By using our Services and the Website or by otherwise giving us
							your information under the concerned Terms of Use available at
							Terms & Conditions, you agree to the terms of this Privacy Policy.
							You also expressly consent to our use and disclosure of your
							Personal Information (as defined below) in the manner prescribed
							under this Privacy Policy and further signify your agreement to
							this Privacy Policy. If you do not agree to this Privacy Policy,
							kindly do not use our Services and the Website, or share any of
							your information in any manner whatsoever with us.
						</p>
						<h4 className="title">COLLECTION OF INFORMATION</h4>
						<ul>
							<li>
								We may collect and process information from you, through your
								access and use of the Website and Services. We may collect and
								process personal information provided by you, including but not
								limited to any information that identifies or can be used to
								identify, contact, or locate you, such as your name, address,
								email address, phone number, gender, photograph, date of birth,
								bank account details, login credentials and passwords.
							</li>
							<li>
								We may also receive data from or about the computer, mobile
								phone, or other devices you use to access the Website, including
								when multiple users log in from the same device. This may
								include network and communication information, such as your IP
								address or phone number, and other information about things like
								your internet service, operating system, location, the type
								(including identifiers) of the device or browser you use, or the
								pages you visit. For example, we could request device
								information to improve how our Services work on your device.
							</li>
							<li>
								We may also receive information regarding the details and
								transaction history in connection with your use of the Website.
							</li>
							<li>
								We may also get information about you from other sources. For
								example, if you create or log into your account through any
								associated service, we will receive information from that
								service (such as your username and profile information) via the
								authorization procedures used by that service. The information
								we receive depends on which services you authorize and any
								options that are available.
							</li>
							<li>
								We may use cookies, web beacons, tracking pixels, and other
								tracking technologies on the Website to help customize the
								Website and improve your experience.
							</li>
						</ul>
						<p>
							The information that we receive from you as detailed in the
							paragraph above is hereinafter, collectively referred to as
							“Personal Information.”
						</p>

						<h4 className="title">USE OF THE INFORMATION COLLECTED</h4>
						<p>Use of the Information for Services </p>
						<h6>
							The primary goal of the Company in collecting the Personal
							Information is to enable access to and use of the Website and
							Services by you. We may use the Personal Information provided by
							you in the following ways:
						</h6>
						<ul>
							<li>To provide access to and use of the Website;</li>
							<li>To provide access to and use of the Services;</li>
							<li>
								To observe and analyze usage, diagnose problems and issues, and
								improve and administer the quality and performance of the
								Website and Service;
							</li>
							<li>
								To confirm your identity in order to determine your eligibility
								to access and use the Website and avail the Services;
							</li>
							<li>
								Remember the basic information provided by you for effective and
								efficient access to the Website and Service;
							</li>
							<li>
								To notify you about any changes to the Website, Services, and
								this Privacy Policy;
							</li>
							<li>
								For the purpose of sending administrative notices, Service
								related alerts, marketing communications and advertisements and
								similar communication from time to time;
							</li>
							<li>
								For internal operations such as market research,
								troubleshooting, project planning, and devising strategies
								against fraud and other criminal activity;
							</li>
							<li>
								To enforce the provisions of this Privacy Policy and Terms of
								Use; and
							</li>
							<li>
								To disclose your Personal Information to any of our affiliates
								or related entities in connection with the operation and
								management of the Website and Services in accordance with
								applicable laws.
							</li>
						</ul>

						<h4 className="title">Cookies and IP Address </h4>
						<ul>
							<li>
								Cookies are small portions of information saved by your browser
								onto your computer or mobile (“Cookies”). Cookies are used to
								record various aspects of your visit and assist us to provide
								you with uninterrupted service.
							</li>
							<li>
								We may use information collected from our Cookies to identify
								user behavior and to serve content and offers based on your
								profile, in order to personalize your experience and in order to
								enhance the convenience of the users of our Website and
								Services.
							</li>
							<li>
								Most browsers are set to accept cookies by default. You can
								remove or reject cookies but be aware that such action could
								affect the availability and functionality of the Website. You
								may not decline web beacons. However, they can be rendered
								ineffective by declining all cookies or by modifying your web
								browser’s settings to notify you each time a cookie is tendered,
								permitting you to accept or decline cookies on an individual
								basis.
							</li>
							<li>
								You acknowledge and agree that third party service providers may
								use Cookies or similar technologies to collect information about
								your pattern of accessing and using the Website and availing the
								Services, in order to inform, optimize, and provide
								advertisements based on your visits to the Website and general
								browsing pattern and report how the third party service
								provider’s advertisement impressions and interactions with these
								impressions and services are in relation to your visits on such
								third party’s website.
							</li>
							<li>
								We neither have access to, nor does the Privacy Policy govern
								the use of Cookies or other tracking technologies that may be
								placed by third party service providers on the Website. These
								parties may permit you to opt out of tailored advertisement at
								any time, however, to the extent advertising technology is
								integrated into the Website and Services, you may still receive
								advertisements and related updates even if they or you choose to
								opt-out of tailored advertising. We assume no responsibility or
								liability whatsoever for your receipt or use of such tailored
								advertisements.
							</li>
							<li>
								We may use any data that is automatically captured by the
								cookies on our Website and Services including your device name,
								operating system version, configuration of the application,
								time, and date of your use of the Website and Service, IP
								address and other such related statistics. Every computer/mobile
								device connected to the internet is given a domain name and a
								set of numbers that serve as that computer’s or device’s IP
								address. When you request a page from any page within the
								Website, our web servers automatically recognize your domain
								name and IP address to help us identify your location. The
								domain name and IP address reveal nothing personal about you
								other than the IP address from which you have accessed the
								Website.
							</li>
						</ul>

						<h4 className="title">SHARING OF INFORMATION </h4>
						<h5>Sharing</h5>
						<p>
							Other than as mentioned elsewhere in this Privacy Policy, we may
							share aggregated demographic information with third parties. This
							is not linked to any Personal Information that can identify an
							individual person. We will however not be liable for transfer of
							any Personal Information resulting from failure of third-party
							service providers, cyber-attacks or terrorism or any other event
							beyond the Company’s control.{" "}
						</p>
						<h5>Consulting</h5>
						<p>
							We may partner with another third party to provide certain
							specific services if required. When you sign-up for such services,
							we may share such Personal Information that is necessary for the
							third party to provide these services. Our contractual
							arrangements with such third parties restrict these parties from
							using Personal Information except for the explicit purpose of
							providing the specific services agreed to be provided by such
							third party.
						</p>
						<h5>Targeted Advertising</h5>
						<p>
							You expressly acknowledge and agree that we may also share or
							transfer information (including Personal Information) in relation
							to your browsing history, cache, IP address and domain name to
							third-party service providers, for the limited purpose of allowing
							or permitting such third-party service providers, including social
							media websites to map similar audiences and display advertisements
							and notifications on websites across the Internet, based on the
							information that is collected by us. Personal Information may also
							be shared or transferred to optimize and display advertisements
							based on your past preferences and visits to the Website. We do
							not allow any unauthorized persons or organization to use any
							information that we may collect from you through this Website.
						</p>
						<h5>Regulatory Disclosures</h5>
						<p>
							We cooperate with government and law enforcement officials and
							private parties to enforce and comply with the law. Thus, we may
							access, use, store, transfer and disclose your information
							(including Personal Information), including disclosure to third
							parties such as government or law enforcement officials or private
							parties as we reasonably determine is necessary and appropriate:
							(i) to satisfy any applicable law, regulation, governmental
							requests or legal process; (ii) to protect the safety, rights,
							property or security of the Company, our Services, the Website or
							any third party; (iii) to protect the safety of the public for any
							reason; (iv) to detect, prevent or otherwise address fraud,
							security or technical issues; and /or (v) to prevent or stop any
							activity we consider to be, or to pose a risk of being, an
							illegal, unethical, or legally actionable activity. Such
							disclosures may be carried out without notice to you.
						</p>
						<h5>Referrals</h5>
						<p>
							When you e-mail/message a Service available on the Website to a
							friend, you and your friend’s names and e-mail addresses/numbers
							are requested. This ensures that your friend will know that you
							have requested that we send them an e-mail/message. Your email and
							your friends’ e-mail addresses will only be used for this purpose
							unless permission is otherwise granted.
						</p>
						<h5>Link to Third Party Websites</h5>
						<p>
							The Website and Services may contain links which may lead you to
							other websites, applications and services offered by third
							parties. Please note that once you leave the Company’s Website and
							Services, you will be subjected to the privacy policy of such
							other third parties. The linked websites, applications and
							services are not necessarily under our control. Please be aware
							that we are not responsible or liable for the privacy practices of
							such third parties. We encourage you to read the privacy policies
							of each and every such third party. If you decide to access any of
							the third-party websites, applications, and services, you do this
							entirely at your own risk.
						</p>

						<h4 className="title">SECURITY OF INFORMATION</h4>
						<ul>
							<li>
								We have put in place necessary security practices and procedures
								to safeguard and secure your Personal Information against
								unauthorized disclosure and use. We only process Personal
								Information in accordance with this Privacy Policy. Access to
								your Personal Information is limited to employees, agents, or
								partners and third parties, who we reasonably believe will need
								that information to enable the Company to provide Services to
								you. However, we are not responsible for the confidentiality,
								security, or distribution of your Personal Information by our
								agents, partners and third parties.{" "}
							</li>
							<li>
								In using the Website and Services, you accept the inherent
								security implications of data transmission over the internet.
								Therefore, the use of the Website and Services will be at your
								own risk, and we assume no liability for any disclosure of
								information due to errors in transmission, unauthorised
								third-party access or other acts of third parties, or acts or
								omissions beyond our reasonable control and you agree not to
								hold the Company responsible for any breach of security.
							</li>
							<li>
								In the event we become aware of any breach of the security of
								your information, we will promptly notify you and take
								appropriate action to the best of its ability to remedy such a
								breach.
							</li>
						</ul>

						<h4 className="title">EXCLUSION</h4>
						<p>
							This Privacy Policy does not apply to any information other than
							information collected by the Company through the Website and
							Services including such information collected in accordance with
							paragraph 1 above on “Collection of Information” and governed by
							the terms of the Terms of Use available at Terms & Conditions.
							This Privacy Policy will not apply to any information available in
							the public domain and unsolicited information provided by you
							through the Website and Services or through any other means. This
							includes, but is not limited to, information posted on any public
							areas of the Website. All such unsolicited information shall be
							deemed to be non-confidential, and we will be free to use,
							disclose such unsolicited information without limitation.
						</p>
						<h4 className="title">RIGHT TO WITHDRAW CONSENT</h4>
						<p>
							The consent that you provide for the collection, use and
							disclosure of your Personal Information will remain valid until
							such time it is withdrawn by you in writing by sending an email to
							contact@firstbitsolutions.com If you withdraw your consent, we
							will stop processing the relevant Personal Information except to
							the extent that we have other grounds for processing such Personal
							Information under applicable laws. We will respond to your request
							within a reasonable timeframe.
						</p>
						<h4 className="title">DATA RETENTION</h4>
						<p>
							We shall not retain Personal Information longer than is necessary
							to fulfil the purposes for which it was collected and as permitted
							by applicable law. Subject to applicable law, if you delete your
							account or withdraw your consent for the collection, use, and
							disclosure of your Personal Information, we may retain your
							Personal Information as needed to comply with our legal and
							regulatory obligations, resolve disputes, conclude any activities
							related to the deletion of an account, to investigate or prevent
							fraud and other inappropriate activity, to enforce our agreements,
							and for other business reasons.
						</p>
						<h4 className="title">RIGHT TO CORRECTION</h4>
						<p>
							The Website allows users access to their Personal Information and
							allows them to correct, amend or delete inaccurate information.
							You are responsible for maintaining the accuracy of the Personal
							Information you submit to us, such as your contact information
							provided as part of account registration. We rely on you to
							disclose all relevant and accurate information and to inform the
							Company of any changes. If you wish to make a request to correct
							or update your Personal Information, you may submit your request
							by sending an email to [insert relevant email address]. We shall
							not be liable for the accuracy or competency of any information
							that you have shared with us.
						</p>
						<h4 className="title">OPT OUT PROCEDURES</h4>
						<p>
							You may opt-out of receiving marketing and certain other kinds of
							non-account related communications from the Company by clicking on
							the “unsubscribe” button or by sending an email to
							contact@firstbitsolutions.com{" "}
						</p>
						<h4 className="title">NOTIFICATION OF CHANGES</h4>
						<p>
							From time to time, we may update this Privacy Policy to reflect
							changes to our information practices with due notice to you via
							the Website or by email. You acknowledge that it is your
							responsibility to check this Privacy Policy for updates
							periodically. Your continued use of the Website and Services
							following the posting of any changes to this Privacy Policy will
							be deemed to constitute an acceptance of those changes.
						</p>
						<h4 className="title">GRIEVANCE REDRESSAL AND GRIEVANCE OFFICER</h4>
						<p>
							In the event that you wish to raise a query or complaint with us,
							please contact our Grievance Officer (contact details set out
							below) who shall acknowledge your complaint within 24 (twenty
							four) hours from the time of receipt of such complaint. Kindly
							note that once your complaint is received, we shall use our best
							efforts to redress it within a period of 15 (fifteen) days from
							the date of receipt of such complaint.
						</p>
						<h6>
							YOU HAVE READ THIS PRIVACY POLICY AND AGREE TO ALL OF THE
							PROVISIONS CONTAINED ABOVE
						</h6>
					</div>
				</div>
			</div>
		</div>
	);
}

export default PrivacyPolicy;
