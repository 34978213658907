import React from "react";
import "../../assets/styles/loading/loading.css";

function Loading() {
	return (
		<div className="loadingAnimationContainer">
			<div className="lds-default">
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
		</div>
	);
}

export default Loading;
