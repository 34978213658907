import React, { useEffect, useState } from "react";

import PlaylistComponent from "./PlaylistComponent";
import "../../../assets/styles/modules/mediaModule.css";
import { useSelector } from "react-redux";
import ShowMediaListCards from "./ShowMediaListCards";

import { getAllPlaylists } from "../../../api/users/userApi";
import ShowMediaListCardsTwo from "./ShowMediaCardsTwo";
import PlaylistComponentTwo from "./PlaylistComponentTwo";

function MediaModule() {
	const [playlistArray, setPlaylistArray] = useState([]);

	const currentPlaylist = useSelector(
		(state) => state.mediaState.currentPlaylist
	);

	const currentUserPlaylist = useSelector(
		(state) => state.userState.user.user.accessDetails.playlists
	);

	console.log(currentUserPlaylist, "current user playlist");

	useEffect(() => {
		getAllPlaylists()
			.then((data) => {
				console.log(data, "all playlist data");
				setPlaylistArray(data);
			})
			.catch((error) => {
				console.error("Error fetching playlists:", error);
			});
	}, []);

	return (
		<div className="mediaModule">
			{currentPlaylist ? (
				<div>
					<ShowMediaListCardsTwo currentPlaylist={currentPlaylist} />
					<br />
				</div>
			) : (
				<>
					{currentUserPlaylist?.map((p) => (
						<PlaylistComponentTwo p={p} />
					))}
				</>
			)}
		</div>
	);
}

export default MediaModule;
