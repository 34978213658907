import React, { useEffect, useState } from "react";
import VideoCard from "./VideoCard";
import "../../../assets/styles/modules/playListComponent.css";
import { getMediaByPlaylist } from "../../../api/media/mediaApi";
import VideoCardTwo from "./VideoCardTwo";
import { useSelector } from "react-redux";

function PlaylistComponentTwo({ p, isVideo }) {
	const [media, setMedia] = useState([]);

	const currentPlaylist = useSelector(
		(state) => state.mediaState.currentPlaylist
	);

	useEffect(() => {
		getMediaByPlaylist(p._id)
			.then((data) => {
				setMedia(data.media);
			})
			.catch((error) => {
				console.error("Error fetching playlists:", error);
			});
	}, [p]);

	return (
		<div className="playlistComponentTwo">
			{currentPlaylist ? (
				<div>
					<h1>{currentPlaylist.label}</h1>
					<br />
				</div>
			) : (
				<>
					<h3 className="playlistComponent__playlistName">{p?.label}</h3>
				</>
			)}

			<div
				className={
					isVideo
						? "playlistComponent__cardHolderTwo sideReel"
						: "playlistComponent__cardHolderTwo"
				}
			>
				{media.map((m) => (
					<VideoCardTwo m={m} />
				))}
			</div>
		</div>
	);
}

export default PlaylistComponentTwo;
