import React, { useEffect } from "react";
import { getMediaByPlaylist } from "../../../api/media/mediaApi";
import PlaylistComponent from "./PlaylistComponent";
import PlaylistComponentTwo from "./PlaylistComponentTwo";

function ShowMediaListCardsTwo({ currentPlaylist }) {
	useEffect(() => {
		getMediaByPlaylist(currentPlaylist._id);
	}, []);

	return <PlaylistComponentTwo p={currentPlaylist} />;
}

export default ShowMediaListCardsTwo;
