import React from "react";

function TNC() {
	return (
		<div>
			<h1>Terms and Conditions</h1>

			<h2>1. Introduction</h2>
			<p>
				Welcome to My FirstBit, a web-based Learning Management System. By
				accessing or using our platform, you agree to be bound by these Terms
				and Conditions. If you do not agree with any part of these terms, please
				do not use our services.
			</p>

			<h2>2. User Conduct and Obligations</h2>
			<ul>
				<li>
					<strong>2.1 Acceptable Use:</strong> You agree not to use My FirstBit
					in any way that is unlawful, harmful, or in violation of these Terms
					and Conditions.
				</li>
				<li>
					<strong>2.2 Content Standards:</strong> You are responsible for
					ensuring that any content you provide or actions you take do not
					infringe upon the intellectual property rights of others.
				</li>
			</ul>

			<h2>3. Intellectual Property Rights</h2>
			<ul>
				<li>
					<strong>3.1 Service Content:</strong> All content provided on My
					FirstBit, excluding user-generated content, is the property of My
					FirstBit or its licensors.
				</li>
				<li>
					<strong>3.2 User-Generated Content:</strong> Users retain all rights
					to the content they create and share on the platform.
				</li>
			</ul>

			<h2>4. Disclaimer of Warranties</h2>
			<p>
				My FirstBit is provided "as is," without any warranty of any kind. My
				FirstBit does not guarantee the accuracy, completeness, or usefulness of
				any information on the service.
			</p>

			<h2>5. Limitation of Liability</h2>
			<p>
				My FirstBit shall not be liable for any indirect, incidental, special,
				consequential, or punitive damages arising out of your access to or use
				of the service.
			</p>

			<h2>6. Changes to Terms</h2>
			<p>
				We reserve the right to modify or replace these Terms at any time. It is
				your responsibility to review these Terms periodically for changes.
			</p>

			<h2>7. Governing Law</h2>
			<p>
				These Terms shall be governed by and construed in accordance with the
				laws of Pune, without regard to its conflict of law provisions.
			</p>

			<h2>8. Contact Information</h2>
			<p>
				If you have any questions about these Terms, please contact us at [Your
				Contact Information].
			</p>
		</div>
	);
}

export default TNC;
