import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../instance";

const base = "api/v1/auth";

export const fetchUserByEmail = createAsyncThunk(
	"users/fetchUserByEmail",
	async () => {
		try {
			console.log("issuing fetch user request");
			const response = await api.get(`${base}/user-info`, {
				withCredentials: true,
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
				},
			});
			const data = response.data;
			console.log("from fetchUser auth api", data);
			return data;
		} catch (error) {
			console.log(error.message);
		}
	}
);

export const logOutUser = async () => {
	try {
		console.log("dispatching /current-user");
		const r = await api.get(`${base}/logout`, {
			withCredentials: true,
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
		});
		const d = r.data;
		return d;
	} catch (error) {
		console.log("error while fetching current user");
	}
};
